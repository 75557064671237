<template>
    <b-overlay :show="showLoader">
        <b-card>
            <h3 style="font-weight: bold;">Izpolnite podatke o oglasu</h3>
            <validation-observer ref="validate">
                <b-form class="form">
                    <advertisement ref="advertisement" v-model="object" type=""/>
                    <b-row>
                        <b-col class="text-center text-md-right">
                            <b-button class="mt-1 mt-sm-0" variant="warning" @click="$router.replace({name: 'admin-advertisement-list'})">PREKLIČI</b-button>
                            <b-button class="mt-1 mt-sm-0 mx-1 mr-sm-0"  variant="secondary" @click.prevent="validationForm">ODDAJ OGLAS</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import Advertisement from '../../Components/Advertisement'
    import {BButton, BCard, BForm, BOverlay, BRow, BCol} from 'bootstrap-vue'
    export default {
        components: {
            ValidationObserver,
            Advertisement,
            BOverlay,
            BCard,
            BForm,
            BButton,
            BRow,
            BCol
        },
        data() {
            return {
                object: {
                    main_photo:null,
                    device_type:[],
                    page_type:[],
                    show_from: '',
                    show_until: '',
                    url:'',
                    active: true
                },
                showLoader: false,
                required
            }
        },
        methods: {
            async validationForm() {
                this.$refs.validate.validate().then(async(success) => {
                    if (success) {
                        await this.addAdd()
                    }
                })
            },
            async addAdd() {
                this.showLoader = true
                try {
                    const isImageUploadSuccessfull = await this.$refs.advertisement.uploadImageToAPI()
                    if (!isImageUploadSuccessfull) {
                        this.$printError('Prišlo je do napake pri nalaganju slik.')
                        return
                    }
                    await this.$http.put('/api/management/v1/advertisement/', this.object)
                    this.$printSuccess('Oglas je dodan')

                    setTimeout(() => {
                        this.$router.push({name: 'admin-advertisement-list'})
                    }, 1000)

                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>